import React from "react";
import Layout from "./src/components/Layout";
// import FormbricksProvider from "./src/components/FormbricksProvider";

export function wrapPageElement({ props, element }) {
   return (
      <>
         {/* <FormbricksProvider /> */}
         <Layout {...props}>{element}</Layout>
      </>
   );
}
