import { createGlobalStyle } from "styled-components";

import fonttw from "../assets/FuturaLT.ttf";
import font from "../assets/FuturaLT-CondensedExtraBold.ttf";
const GlobalStyles = createGlobalStyle`
 :root {
    --main  : #6C3EC6;
    --bg :#f5f1fd ;
    --text : #3f365c;
 } 
 @font-face {
   font-family: 'Futura LT Condensed Extra Bold';
    src: url(${font});
 }
 @font-face {
   font-family : 'Futura LT ';
   src:  url(${fonttw})
 }

 *,
*:before,
*:after {
   box-sizing: border-box;
}
html {
   overflow-x: hidden;
   font-size: 15px;

}
body {
   font-family: 'Futura LT Condensed Extra Bold';

padding: 0;
    margin: 0;
    background-color : var(--bg);
    font-size: 1rem;

}
p {
   font-family: 'Futura LT ';
  /* letter-spacing: 0.5px; */
   margin: 0;
   padding: 0;
}

h1,
h2,
h3,
h4,
h5 {
padding: 0;
   margin: 0;
   font-family: 'Futura LT Condensed Extra Bold';

font-weight : 400;
   text-transform :uppercase;
}
ul {
    list-style : none;
}

/* Lazy loading secret Sauce */
/* .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  } */
.center {
   text-align: center;
}
a {
   text-decoration: none;
}
img {
   max-width: 100%;
}
.active  {
   text-decoration : none; 
 
   position  :  relative;
   transition: background-color 0.3s ease-in;
}
.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--bg);
  
  border-radius : 32px;
}

/* For Scrollbar custom color for brand consistency */
.my-scrollable-element::-webkit-scrollbar-thumb {
   background-color: var(--main);
   border-radius: 5px;
}

.my-scrollable-element::-webkit-scrollbar-thumb:hover {
   background-color: var(--main);
}

.my-scrollable-element::-webkit-scrollbar-track {
   background-color: #f5f5f5;
}
body::-webkit-scrollbar {
   width: 12px;
}
html {
   scrollbar-width: thin;
   scrollbar-color: #ffffff var(--main);
}
body::-webkit-scrollbar-track {
   background: #ffffff;
}
body::-webkit-scrollbar-thumb {
   background-color: var(--text);
   /* border-radius: 6px; */
}

`;

export default GlobalStyles;
