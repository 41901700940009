import { useGSAP } from "@gsap/react";
import { Link } from "gatsby";
import gsap from "gsap";
import UseAnimations from "react-useanimations";

import alertOctagon from "react-useanimations/lib/alertOctagon";

import React, { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import styled from "styled-components";
import logo from "../images/tbfinal.svg";

const menuLinks = [
   { path: "/", label: "Home" },
   { path: "/formarketer", label: " Marketing" },
   { path: "/business", label: "Business" },
];

const RecentNewsStyles = styled.div`
   border: 2px solid var(--bg);
   display: flex;
   /* gap: 3rem; */
   justify-content: space-around;
   position: fixed;
   z-index: 1000;
   bottom: 0;
   width: 100%;
   background-color: var(--main);
   span {
      background-color: var(--main);
      color: var(--bg);
      font-size: 1.2rem;
      /* text-transform: uppercase; */
      padding: 0.4rem 1rem;
      font-weight: 500;
   }
   .b-nav {
      margin-top: 0.8rem;
      font-size: 1.2rem;
      text-transform: lowercase;
      color: var(--bg);
   }
   .recent__one {
      display: flex;
      align-items: center;
   }
   @media screen and (max-width: 768px) {
      visibility: hidden;
   }
`;

const MenuStyles = styled.nav`
   a,
   p {
      color: var(--bg);
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
   }
   /*  menu css*/
   .menu-bar,
   .menu-overlay {
      position: sticky;
      top: 0;
      left: 0;
      height: 8rem;
      width: 100vw;
      padding: 2em;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2;

      background-color: var(--main);
   }
   .menu-bar a,
   .menu-open p {
      color: var(--bg);
      cursor: pointer;
      font-size: 2rem;
      font-weight: 900;
      letter-spacing: 3px;
   }
   .menu-close p {
      color: var(--bg);
      cursor: pointer;
      font-size: 3rem;
   }
   .menu-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      padding: 2em;
      background: var(--main);
      z-index: 2;
      display: flex;
      clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
   }
   .menu-close-icon {
      flex: 2;
      display: flex;
      align-items: flex-end;
      cursor: pointer;
   }
   .menu-copy {
      flex: 4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-top: 2em;
      gap: 2rem;
   }
   .menu-preview {
      flex: 4;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
   }
   .menu-close-icon p {
      font-size: 100px;
      --webkit-text-stroke: 5px #c5fb45;
      line-height: 70%;
   }
   .menu-link-item {
      width: max-content;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
   }
   .menu-link-item-holder {
      position: relative;
   }
   .menu-link-item-holder a {
      color: var(--bg);
      font-size: 90px;
      font-weight: 400;
      /* letter-spacing: 0.2em; */
      line-height: 85%;
   }
   .menu-info {
      display: flex;
   }
   .menu-info-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
   }
   @media screen and (max-width: 900px) {
      .menu-close-icon {
         display: none;
      }
      .menu-copy {
         padding-top: 8em;
      }
      .menu-link-item-holder a {
         /* font-size: 20px;
          */
         font-size: 40px;
      }
      .menu-overlay-bar {
         margin-left: -2rem;
      }
      .menu-close p {
         color: var(--bg);
         cursor: pointer;
         font-size: 1rem;
         /* font-family: "Anton"; */
      }
   }
   @media only screen and (min-width: 768px) and (max-width: 1023px) {
      /* Add your tablet styles here */
      .menu-overlay {
         height: 80vh;
      }
   }
   @media screen and (max-width: 768px) {
      .menu-copy {
         padding-top: 0.2em;
      }
      .menu-overlay {
         height: 100vh;
      }
   }
`;
export default function Menu() {
   const navContainer = useRef();
   const t1 = useRef();
   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
   };
   const handleKeyPress = (event) => {
      if (event.key === "Enter" || event.key === " ") {
         // Handle key press event
         setIsMenuOpen(!isMenuOpen);
      }
   };

   useGSAP(
      () => {
         // console.log(document.querySelectorAll(".menu-link-item-holder")); // Debugging line
         gsap.set(".menu-link-item-holder", { y: 75 });
         t1.current = gsap
            .timeline({ paused: true })
            .to(".menu-overlay", {
               duration: 1.25,
               clipPath: "polygon(0% 0% , 100% 0% , 100% 100% , 0% 100%)",
               ease: "power4.inOut",
            })
            .to(".menu-link-item-holder", {
               y: 0,
               duration: 1,
               stagger: 0.1,
               ease: "power4.inOut",
               delay: -0.75,
            });
      },
      { scope: navContainer }
   );

   useEffect(() => {
      if (isMenuOpen) {
         t1.current.play();
      } else {
         t1.current.reverse();
      }
   }, [isMenuOpen]);
   return (
      <MenuStyles ref={navContainer}>
         <div className="menu-bar">
            <div className="menu-logo">
               <Link to="/">
                  <img src={logo} alt="TradeBooker Logo" />
               </Link>
            </div>
            <div
               className="menu-open"
               onClick={toggleMenu}
               tabIndex={0}
               role="button"
               onKeyDown={handleKeyPress}
            >
               <p>Menu</p>
            </div>
         </div>
         <div className="menu-overlay">
            <div className="menu-copy">
               <div className="menu-links">
                  {menuLinks.map((link, index) => (
                     <div
                        className="menu-link-item"
                        key={index}
                        onClick={toggleMenu}
                        onKeyDown={handleKeyPress}
                        tabIndex={0}
                        role="button"
                     >
                        <div className="menu-link-item-holder">
                           <Link to={link.path} className="menu-link">
                              {link.label}
                           </Link>
                        </div>
                     </div>
                  ))}
               </div>
               <div className="menu-info">
                  <div className="menu-info-col">
                     <a href="/">X &#8599;</a>
                     <a href="/">X Instagram</a>
                     <a href="/"> Twitter</a>
                     <a href="/">X Facebook</a>
                  </div>
                  <div className="menu-info-col">
                     <p>info@tradeBooker.com</p>
                     <p>+61 434853334</p>
                  </div>
               </div>
            </div>

            <div className="menu-overlay-bar">
               <div
                  className="menu-close"
                  onClick={toggleMenu}
                  tabIndex="0"
                  role="button"
                  onKeyDown={handleKeyPress}
               >
                  <p>Close</p>
               </div>
            </div>
         </div>
         <RecentNewsStyles>
            <div className="recent__one">
               <UseAnimations
                  animation={alertOctagon}
                  size={45}
                  strokeColor="#fff"
               />
               <span>Contact us For Free Stuff 🚀🚀</span>
            </div>
            <p className="b-nav">+61 434853334</p>
            <p className="b-nav">info@tradebooker.com.au</p>
         </RecentNewsStyles>
      </MenuStyles>
   );
}
