// import { Link } from "gatsby";
import React from "react";
import UseAnimations from "react-useanimations";
import styled from "styled-components";
import facebook from "react-useanimations/lib/facebook";
import instagram from "react-useanimations/lib/instagram";
import Logo from "../images/tbfinal.svg";
const FooterStyles = styled.footer`
   width: 100%;
   /* height: 50vh; */
   background: var(--main);
   padding: 16px;
   margin-bottom: 3rem;
   footer.top > img {
      height: 200px;
      width: 200px;
      margin: 0 auto 50px;
   }

   footer {
      position: relative;
      margin: 0 30px;
      color: var(--bg);
   }

   footer.top {
      display: grid;
      border-bottom: 2px solid var(--bg);
      padding-bottom: 20px;
   }

   footer.bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column-reverse;
      gap: 8px;
      padding: 20px 0;
      text-align: center;
   }

   footer.top .links {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
      margin-bottom: 30px;
   }

   .links-column {
      display: grid;
      gap: 10px;
      text-align: center;
   }
   .links-column > a {
      font-size: 1.5rem;
      /* font-family: "Cormorant Garamond"; */
      color: var(--bg);
   }

   footer h2 {
      font-family: "Futura LT Condensed Extra Bold";
      margin: 0 0 10px;
      font-size: 2rem;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: rgb(255 255 255 / 96%);
   }

   footer p {
      margin: 0;
   }

   .legal > a {
      margin: 0 0 0 4px;
   }

   .legal,
   .copyright {
      font-size: 16px;
   }

   .legal > span {
      margin-right: 10px;
   }

   footer.top .socials {
      position: absolute;
      left: 50%;
      bottom: 10px;
      translate: -50% 0;
      display: flex;
      align-items: center;
      gap: 18px;
      height: 56px;
   }

   footer.top .socials > a {
      font-size: 24px;
   }

   .socials-column :is(h2, p) {
      display: none;
   }
   .social {
      font-size: 2rem;
   }

   @media (width > 400px) {
      .links-column {
         text-align: left;
      }

      footer.top .links {
         grid-template-columns: repeat(2, 1fr);
         margin-right: 50px;
      }

      footer.bottom {
         display: flex;
         align-items: center;
         justify-content: space-between;
         flex-direction: row;
         gap: 20px;
         text-align: left;
      }
      .bottom p a {
         color: white;
      }

      footer.top .socials {
         left: 0;
         translate: 0;
      }

      footer.top > img {
         /* margin: 0 0 50px; */
         margin-left: 6px;
      }
   }

   @media (width >= 640px) {
      footer {
         margin: 0 auto;
         max-width: 1000px;
         padding: 0;
      }

      footer.top .links {
         grid-template-columns: repeat(3, 1fr);
      }

      footer.top .socials {
         position: static;
      }

      footer.bottom {
         justify-content: flex-start;
      }

      .socials-column :is(h2, p) {
         display: block;
      }
   }
   @media only screen and (min-width: 768px) and (max-width: 1023px) {
      /* Add your tablet styles here */
      height: 110vh;
   }
`;
export default function Footer() {
   return (
      <FooterStyles>
         <footer className="top">
            <img src={Logo} alt="asu logo" className="logo" />

            <div className="links">
               <div className="links-column">
                  <h2>QUICKS LINKS</h2>
                  <a href="/">Home</a>
                  <a href="/business"> Business</a>
                  <a href="/formarketer">Digital Marketing</a>
               </div>

               <div className="links-column">
                  <h2>HEAD OFFICE</h2>
                  <p>Melbourne, Victoria</p>
                  <h2>Contact Us</h2>
                  <p>+61 434853334</p>
               </div>
               <div className="links-column socials-column">
                  <h2>Social Media</h2>
                  <p className="social">
                     Follow us on social media to find out the latest updates on
                     our progress.
                  </p>
                  <div className="socials">
                     <a
                        href="https://www.facebook.com/TradeBooker"
                        target="_blank"
                        rel="noreferrer"
                     >
                        {" "}
                        <UseAnimations
                           animation={facebook}
                           size={35}
                           strokeColor="#ffff"
                        />
                     </a>
                     <a
                        href="https://www.instagram.com/tradebooker.com.au/"
                        target="_blank"
                        rel="noreferrer"
                     >
                        <UseAnimations
                           animation={instagram}
                           size={35}
                           strokeColor="#ffff"
                        />
                     </a>

                     {/* <a className="fa-brands fa-instagram"></a> */}
                     {/* <a className="fa-brands fa-linkedin" href="/">
                        Instagram
                     </a> */}
                  </div>
               </div>
            </div>
         </footer>
         <footer className="bottom">
            <p className="copyright">
               © 2024 All rights reserved by Tradebooker{" "}
            </p>
            {/* <p>
               Made by :{" "}
               <a
                  href="https://www.instagram.com/llinnovation231/"
                  target="_blank"
                  rel="noopener noreferrer"
               >
                  LocallayerInnovation
               </a>
            </p> */}
            {/* <div className="legal">
               <a href="/"> License </a>
               <a href="/"> Terms </a>
               <a href="/"> Privacy </a>
            </div> */}
         </footer>
      </FooterStyles>
   );
}
