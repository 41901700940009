import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import { ReactLenis } from "lenis/react";
import GlobalStyles from "../styles/globalStyles";

export default function Layout({ children }) {
   return (
      <>
         <ReactLenis root>
            <GlobalStyles />
            <Nav />
            {children}
            <Footer />
         </ReactLenis>
      </>
   );
}
